import { Component, OnInit, OnChanges, forwardRef, Input } from '@angular/core';
import { 
	NG_VALUE_ACCESSOR,
	NG_VALIDATORS,
	ControlValueAccessor, 
	Validator, 
	AbstractControl, 
	FormControl 
} from '@angular/forms';


const noop = () => { };

@Component({
	selector: 'radio-group',
	templateUrl: './radio-group.component.html',
	styleUrls: ['./radio-group.component.css'],
	providers: [
	{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => RadioGroupComponent),
		multi: true
	},
	{
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => RadioGroupComponent),
		multi: true,
	}
	]
})
export class RadioGroupComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {

	@Input() inlineRadio;
	@Input() listData;
	@Input() title;
	@Input() isValid;
	@Input() name;
	result:any;
	value_radio:any;
	group_radios:any = {};

	private onChangeCallback: (_: any) => void = noop;
	private onTouchedCallback: () => void = noop;

	constructor() { }

	ngOnInit() {
	}

	ngOnChanges(changes){
		if(changes.isValid){
			this.isValid = changes.isValid.currentValue;
		}
	}

	get value(){
		return this.result;
	}

	set value(v:any){
		if(v !== this.result){
			this.result = v;
			this.onChangeCallback(this.result);
		}
	}

	onChange(event){
		this.result = this.value_radio;
		this.onChangeCallback(this.value_radio);
	}

	public writeValue(value: any) {
		if (value !== this.result) {
			this.result = value;
		}
	}


	public registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	public validate(c: FormControl){
		return (this.result) ? null : {
			inputError: {
				valid: false,
			},
		};
	}

}
