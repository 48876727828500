import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appImagesSizesBanner]'
})
export class ImagesSizesBannerDirective {
	divW:any;
	divH:any;
	divAspect:any;
	position:number = 0;

	
	checksize(){
		this.el.nativeElement.children[0].onload=()=>{
			this.divW = this.el.nativeElement.clientWidth;
			this.divH = this.el.nativeElement.clientHeight;
			this.divAspect = this.divW / this.divH;

			var h = this.el.nativeElement.children[0].naturalHeight;
			var w = this.el.nativeElement.children[0].naturalWidth;
			var aspectRatio = w / h;

			if(this.divAspect >= aspectRatio){
					this.el.nativeElement.children[0].style.height = (this.divW / aspectRatio) + "px";
					this.el.nativeElement.children[0].style.width = this.divW +  "px";
					var mt = (this.divH / 2) - ((this.divW / aspectRatio) / 2);
					this.el.nativeElement.children[0].style.marginTop = (mt) + "px"
					// this.el.nativeElement.children[0].style.marginLeft = "0px"
				} else {
					this.el.nativeElement.children[0].style.height = this.divH + "px";
					this.el.nativeElement.children[0].style.width = (this.divH * aspectRatio) +  "px";
					var ml = (this.divW / 2) - ((this.divH * aspectRatio) / 2);
					// this.el.nativeElement.children[0].style.marginLeft = ml + "px"
					this.el.nativeElement.children[0].style.marginTop = "0px"
			}
		}
	}


	ngAfterContentChecked(){
		this.checksize();
	}
	ngOnInit(){
		//this.el.nativeElement.style.height = this.el.nativeElement.clientWidth + "px";
		this.checksize()
	}
    constructor(private el: ElementRef) {
    	
    }
}
