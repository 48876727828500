import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
import { HttpModule, JsonpModule, Http} from '@angular/http';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HomeBannerComponent } from './home-banner/home-banner.component';
import { HomeConveniosComponent } from './home-convenios/home-convenios.component';
import { HomeOpinionesComponent } from './home-opiniones/home-opiniones.component';
import { HomeContactanosComponent } from './home-contactanos/home-contactanos.component';
import { HomePopupComponent } from './home-popup/home-popup.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { BeneficiosComponent } from './beneficios/beneficios.component';
import { SeguridadComponent } from './seguridad/seguridad.component';
import { ContactanosComponent } from './contactanos/contactanos.component';
import { ConveniosComponent } from './convenios/convenios.component';
import { VinculateComponent } from './vinculate/vinculate.component';
import { ConocenosComponent } from './conocenos/conocenos.component';
import { HeaderComponent } from './shared/header/header.component';
import { PieComponent } from './shared/pie/pie.component';
import { ImagesSizesSquareDirective } from './shared/directives/images-sizes-square.directive';
import { ImagesSizesBannerDirective } from './shared/directives/images-sizes-banner.directive';
import { SquareDivDirective } from './shared/directives/square-div.directive';
import { CreditosSliderComponent } from './shared/creditos-slider/creditos-slider.component';
import { PaginaConvenioComponent } from './pagina-convenio/pagina-convenio.component';
import { ConveniosPageComponent } from './shared/convenios-page/convenios-page.component';
import { FormularioComponent } from './formulario/formulario.component';

import { ServiceManagerService } from './services/service-manager.service';
import { HtmlSafePipe } from './pipes/html-safe.pipe';
import { RadioGroupComponent } from './shared/radio-group/radio-group.component';

import { SlickModule } from 'ngx-slick';
import { DeviceDetectorModule } from 'ngx-device-detector';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HomeBannerComponent,
    HomeConveniosComponent,
    HomeOpinionesComponent,
    HomeContactanosComponent,
    HomePopupComponent,
    ServiciosComponent,
    BeneficiosComponent,
    SeguridadComponent,
    ContactanosComponent,
    ConveniosComponent,
    VinculateComponent,
    ConocenosComponent,
    HeaderComponent,
    PieComponent,
    ImagesSizesSquareDirective,
    ImagesSizesBannerDirective,
    SquareDivDirective,
    CreditosSliderComponent,
    PaginaConvenioComponent,
    ConveniosPageComponent,
    FormularioComponent,
    HtmlSafePipe,
    RadioGroupComponent
  ],
  imports: [
    BrowserModule,
    HttpModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SlickModule.forRoot(),
    DeviceDetectorModule.forRoot()
  ],
  providers: [ServiceManagerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
