import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appSquareDiv]'
})
export class SquareDivDirective {
		divW:any;
		divH:any;

		CheckSize(){

			this.divW = this.el.nativeElement.clientWidth;
			this.el.nativeElement.style.height = this.divW + "px"
			console.log(this.divW)

		}

	ngOnInit(){
		this.CheckSize()
	}


  constructor(private el: ElementRef) { }

}
