import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { BeneficiosComponent } from './beneficios/beneficios.component';
import { SeguridadComponent } from './seguridad/seguridad.component';
import { ContactanosComponent } from './contactanos/contactanos.component';
import { ConveniosComponent } from './convenios/convenios.component';
import { VinculateComponent } from './vinculate/vinculate.component';
import { ConocenosComponent } from './conocenos/conocenos.component';
import { PaginaConvenioComponent } from './pagina-convenio/pagina-convenio.component';
import { FormularioComponent } from './formulario/formulario.component';


const routes: Routes = [
	{
		path: '',
		children: [
		{
			path:'', component: HomeComponent,
			data: {
				module:'home',
				pages:'menu'
			}
		},
		{
			path:'servicios', component: ServiciosComponent,
			data: {
				module:'servicios',
				pages:'menu'
			}
		},
		{
			path:'beneficios', component: BeneficiosComponent,
			data: {
				module:'beneficios',
				pages:'menu'
			}
		},
		{
			path:'conocenos', component: ConocenosComponent,
			data: {
				module:'conocenos',
				pages:'menu'
			}
		},
		{
			path:'convenios', component: ConveniosComponent,
			data: {
				module:'convenios',
				pages:'menu'
			}
		},
		{
			path:'vinculate', component: VinculateComponent,
			data: {
				module:'vinculate',
				pages:'menu'
			}
		},
		{
			path:'seguridad', component: SeguridadComponent,
			data: {
				module:'seguridad',
				pages:'menu'
			}
		},
		{
			path:'contactanos', component: ContactanosComponent,
			data: {
				module:'contactanos',
				pages:'menu'
			}
		},
		{
				//funciona!
			path:'convenios/:Title', component: PaginaConvenioComponent,
			data: {
				module:'paginaConvenios',
				page:'menu'
			}
		},
			{
				//funciona!
			path:'formulario', component: FormularioComponent,
			data: {
				module:'vinculate',
				page:'menu'
			}
		},
		]
	}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routableComponents = [
	HomeComponent,
	ServiciosComponent,
    BeneficiosComponent,
    SeguridadComponent,
    ContactanosComponent,
    ConveniosComponent,
    VinculateComponent,
    ConocenosComponent,
    PaginaConvenioComponent,
]