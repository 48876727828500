import { Component, OnInit } from '@angular/core';
import { environment } from './../../environments/environment';
import { ServiceManagerService } from '../services/service-manager.service';

@Component({
  selector: 'app-beneficios',
  templateUrl: './beneficios.component.html',
  styleUrls: ['./beneficios.component.css']
})
export class BeneficiosComponent implements OnInit {

  constructor(private service: ServiceManagerService) { }

  afiliaciones: any = [];
  servicios_convenios: any = [];

  ngOnInit() {
  	this.service.getBeneficios()
  		.subscribe( beneficios => {
  			console.log(beneficios);
  			this.afiliaciones = beneficios.afiliacion;
  			this.servicios_convenios = beneficios.serv_convenios;

        this.afiliaciones = beneficios.afiliacion.map( item => {
          item.image.url = environment.URL_SERVER + item.image.url
          item.image.thumb.url = environment.URL_SERVER + item.image.thumb.url
          return item;
        });

        this.servicios_convenios = beneficios.serv_convenios.map( item => {
          item.image.url = environment.URL_SERVER + item.image.url
          item.image.thumb.url = environment.URL_SERVER + item.image.thumb.url
          return item;
        });

        
  		})
  }

}
