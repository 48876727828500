import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ServiceManagerService } from '../services/service-manager.service';
import { environment } from './../../environments/environment';

import * as data from '../services/data.services';

@Component({
  selector: 'app-vinculate',
  templateUrl: './vinculate.component.html',
  styleUrls: ['./vinculate.component.css'],
  //encapsulation: ViewEncapsulation.None,
})
export class VinculateComponent implements OnInit {
	formularios : any ;
	data : any ;
	BackPosition = 7;
	Position = 0;
	FrontPosition = this.Position + 1;
	SliderLength:any;

	informacion_institucional:any;
	tarifas: any;
	creditos: any;

	arrClases:any = []


	ChangePosition(ev){

	}
  constructor(private service: ServiceManagerService) { }

  ngOnInit() {
  

  	this.service.getDocuments()
  	.subscribe( data => {
  		console.log(data)
  		this.formularios = data.documents.map( link => {
  			link.Link = environment.URL_SERVER + link.file.url
  			link.router = 'link'
  			return link
  		});

  		this.formularios.unshift({
		    "id":1,
		    "name": "Formulario de afiliación o actualización de datos",
		    "Link": "/formulario",
		    "router": "internal"
		  })

  		console.log(this.formularios )
  	});

  	this.service.getVinculate()
  		.subscribe( data => {
  			this.informacion_institucional = data.informacion;
  			this.tarifas = data.tarifas;

  			this.informacion_institucional = data.informacion.map( item => {
	  			item.image.url = environment.URL_SERVER + item.image.url
	  			item.image.thumb.url = environment.URL_SERVER + item.image.thumb.url
	  			return item;
	  		});

	  		this.tarifas = data.tarifas.map( item => {
	  			item.image.url = environment.URL_SERVER + item.image.url
	  			item.image.thumb.url = environment.URL_SERVER + item.image.thumb.url
	  			return item;
	  		});

	  		this.arrClases = data.tipo_creditos.map( (item, i) => {
				item.key = 'item_'+(i+1)
				item.image.url = environment.URL_SERVER + item.image.url
				return item;
			})

			console.log(this.arrClases)

			if(this.arrClases.length > 0){
				this.data = data.creditos.map( (item, i) => {
	  			return {
	  				"id": (i+1),
	  				"Titulo": item.name,
	  				"TextIcon": item.name,
	  				"Clase": this.arrClases[i]['key'],
	  				"Icon" : this.arrClases[i]['image']['url'],
	  				"Picture": environment.URL_SERVER + item.image.url,
	  				"Description" : item.description
	  			};
	  		});

	  		this.SliderLength = this.data.length
	  		this.BackPosition = this.data.length - 1
			}

	  		

  		})

  }

}
