import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ServiceManagerService } from './../services/service-manager.service';
import { Familiar } from './../shared/familiar';

@Component({
	selector: 'app-formulario',
	templateUrl: './formulario.component.html',
	styleUrls: ['./formulario.component.css']
})



export class FormularioComponent implements OnInit {


	@ViewChild('pdf_url') pdf_url: ElementRef;
	form: any = {};
	errors: any;
	familiares: any[] = [];
	validators = {
		email : /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/,
		phone: /^[0-9]{7}$/,
		mobil: /^[3]{1}[0-9]{9}$/,
		number: /^[0-9]+$/


	}

	tipo_formato = []
	genero = []
	estado_civil = []
	departamento = []
	ciudades = []
	paises = []
	tipo_residencia = []
	tipo_salario = []
	tipo_contrato = []
	forma_abono = []
	tipo_ocupacion = []
	tipo_familiar = []
	radio_options = [
		{value: 1, name:'Si'},
		{value: 2, name:'No'}
	]


	constructor(private services: ServiceManagerService) {
		this.familiares.push(new Familiar())

	
	}

	ngOnInit() {
		this.form.type_format_id = "";
		this.form.place_birth = "";
		this.form.type_status_id = "";
		this.form.type_payment_id = "";
		this.form.city_id = "";
		this.form.departament_id = "";
		this.form.type_home_id = "";
		this.form.type_salary_id  = "";
		this.form.type_occupation_id = "";
		this.form.type_gender_id = "";
		this.form.type_deal_id  = "";
		this.form.country_id_ext = "";
		this.form.city_id_ext = "";

			this.services.getInfo()
			.subscribe( data => {
				let lists:any = {};
				data = Object.keys(data).map( key => {
					lists[key] = data[key].map( item => {
						return {id: item.id, desc: item.name}
					})
				});


				this.tipo_formato = lists.tipo_formato;
				this.genero = lists.tipo_genero;
				this.estado_civil = lists.tipo_estado;
				this.departamento = lists.departamento;
				this.ciudades = lists.ciudad;
				this.paises = lists.pais;
				this.tipo_residencia = lists.tipo_vivienda;
				this.tipo_salario = lists.tipo_de_salario;
				this.tipo_contrato = lists.tipo_contrato;
				this.forma_abono = lists.tipo_abono;
				this.tipo_ocupacion = lists.tipo_ocupacion;
				this.tipo_familiar = lists.tipo_familiar;
			})
	}

	duplicateRow(){
		this.familiares.push(new Familiar())
		console.log(this.familiares)
	}

	deleteRow(index){
		this.familiares.splice(index, 1)
	}

	onSubmit(){
		this.form['familiares'] = this.familiares;
		console.log(this.form);
		this.services.sendVinculacion(this.form)
			.subscribe(data => {

				if(data.errors.length > 0){
					this.errors = data.errors;
					return;
				}

				this.pdf_url.nativeElement.href = this.services.baseUrl( data.link_pdf)
				console.log(this.pdf_url.nativeElement)
				this.pdf_url.nativeElement.click();


				console.log(data);
			})
		
		
	}

	lazyBoolean(condition){
		return new Promise( resolve => {
			setTimeout( _ => {
				console.log(condition)
				resolve(condition)
			}, 100)	
		})
		
	}

}
