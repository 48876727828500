import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as data from '../services/data.services';
import { environment } from './../../environments/environment';
import { ServiceManagerService } from '../services/service-manager.service';

@Component({
  selector: 'app-conocenos',
  templateUrl: './conocenos.component.html',
  styleUrls: ['./conocenos.component.css'],
  //encapsulation: ViewEncapsulation.None
})
export class ConocenosComponent implements OnInit {
	NuestraHistoria : any;
	Junta : any ;
	BackPosition = 3;
	Position = 0;
	FrontPosition = this.Position + 1;
	SliderLength:any;
	ArrClasses = ["JuntaDir", "ControlSoc", "Apelaciones",   "RevFisc" ]
  icons = ["junta1.png", "junta2.png", "junta3.png",   "junta4.png" ]
  link_estatutos;
  link_reglamento;

	

	ChangePosition(ev){
		
	}
  constructor(private service: ServiceManagerService) { }

  ngOnInit() {
    this.link_estatutos = environment.URL_SERVER +  '/formatos/estatutos_fondo_de_empleados.pdf'
    this.link_reglamento = environment.URL_SERVER +  '/formatos/reglamento_de_credito.pdf'

  	this.NuestraHistoria = data.Historia.map( item => {
  		item['selected'] = false
  		return item;
  	});

  	this.service.getComites()
  		.subscribe( comite => {
  			this.Junta = comite.map( (item, i) => {
          console.log(this.ArrClasses[i])
  				return {
  					"id": (i+1),
  					"Clase": this.ArrClasses[i],
  					"Description": item.description,
  					"Titulo": item.title,
  					"Objeto": item.sub_title,
            "TextIcon": item.title,
            "Icon": "/assets/img/" + this.icons[i]
  				}
  			} )
  		})
  	
  }

}
