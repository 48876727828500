import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as data from '../../services/data.services';
import { ServiceManagerService } from '../../services/service-manager.service';
import { environment } from './../../../environments/environment';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-convenios-page',
  templateUrl: './convenios-page.component.html',
  styleUrls: ['./convenios-page.component.css'],
})
export class ConveniosPageComponent implements OnInit {
	Categorias:any = [];

  Conv:any = [];
  FilteredConvenios:any[];
  Position = 0
  max_colors = 6;

  ChangePosition(ev){

    this.Position = ev;
    if(ev == ""){
      this.FilteredConvenios = this.Conv
      return;
    }

    this.FilteredConvenios = this.Conv.filter( c => c.cat == ev);

  }
  constructor(private service: ServiceManagerService, private route: ActivatedRoute) { }

  ngAfterViewInit(){
      setTimeout( () => {
       
      }, 100)
  }

  ngOnInit() {

    this.route.params.subscribe( params => {
       window.scrollTo(0,0)  
    })



    this.service.getConvenios()
    .subscribe( data => {

      let index = 1;
      this.Categorias = data.tipo_convenio.map( (cat, i) => {
        cat.class = 'conv_' + (index+1)
        cat.image = environment.URL_SERVER + '/' +cat.image.url;

        index = (index < (this.max_colors - 1)) ? index+1 : 0
        return cat;
      })


      this.Categorias.unshift({
        id : 0,
        image: '/assets/img/todos.svg',
        class: 'conv_0',
        name: 'Todos'
      });

      console.log(this.Categorias)


      this.Conv = data.convenios


      this.Conv = this.Conv.map(item => {
        let short_desc =  item.description.replace(/<\/?[^>]+(>|$)/g, "") < 100 ? 
        item.description.replace(/<\/?[^>]+(>|$)/g, "") :
        item.description.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 100) + '...'

        return {
          "id": item.id,
          "Title": item.title,
          "Short_Desc": short_desc,
          "Descuento": (item.discount ) ? item.discount + '%': null,
          "picture": environment.URL_SERVER + item.image.url,
          "cat": item.type_agreement_id
        }
      })

      this.FilteredConvenios = this.Conv

 
      
    });
  }

}
