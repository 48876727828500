import { Component, OnInit } from '@angular/core';
import * as data from "../services/data.services";
import { environment } from './../../environments/environment';
import { ServiceManagerService } from '../services/service-manager.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
	selector: 'app-home-convenios',
	templateUrl: './home-convenios.component.html',
	styleUrls: ['./home-convenios.component.css']
})
export class HomeConveniosComponent implements OnInit {

	Conv:any = [];
	convLength:number;
	index:any;
	DisplayConvenios: any = [];
	Position = 0;
	slideConfig:any = {};


	constructor(private service: ServiceManagerService, private deviceService: DeviceDetectorService) { 
		
	}

	ngOnInit() {
		
		this.slideConfig = this.deviceService.isMobile() ? {
			"slidesToShow": 1, "slidesToScroll": 1
		} : { "slidesToShow": 6, "slidesToScroll": 1};

		this.slideConfig['autoplay'] = true;
		this.slideConfig['autoplaySpeed'] = 2000;

		this.service.getConvenios()
		.subscribe( data => {
			console.log(data)

			this.Conv = data.convenios

			this.Conv = this.Conv.map( (item, i) => {
				return {
					"id": item.id,
					"Title": item.title,
					"picture" : environment.URL_SERVER + item.image.url
				}
			})

		});
	}
}
