import { Component, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { ServiceManagerService } from '../services/service-manager.service';
import { environment } from './../../environments/environment';
import * as data from '../services/data.services';

@Component({
  selector: 'app-pagina-convenio',
  templateUrl: './pagina-convenio.component.html',
  styleUrls: ['./pagina-convenio.component.css'],
  //encapsulation: ViewEncapsulation.None,
})
export class PaginaConvenioComponent   {
	infoConvenios:any;
  convenios:any = [];
  slideConfig:any;

  escogerConvenio(list, id){
    var returnData = [];

    for(let item of list){
      if (item.id == id) {
        returnData.push(item)
        this.infoConvenios = {
          "Title": item.title, 
          "Long_Desc": item.description, 
          "picture": environment.URL_SERVER + item.image.url,
          "slides":  item.carrousel.map( img => {
              return environment.URL_SERVER + img.url.url
          })
        }
      }
    }
    return returnData
  }
  constructor(private route: ActivatedRoute, private service: ServiceManagerService) { }

  ngOnInit() {
    this.slideConfig = {
      "slidesToShow": 1, 
      "slidesToScroll": 1, 
      "infinite": true,
    };

  this.route.params.subscribe(params=>{
    this.service.getConvenios()
    .subscribe( data => {
      this.convenios = data.convenios

      this.escogerConvenio(this.convenios, parseInt(params.Title))
    });


  })

}

loadImge(image){
  if(event['path'][0].width > event['path'][0].height){
    image.className = "horizontal-image"
  }else{
    image.className = "vertical-image"
  }
}


afterChange(event){

}

}
