import { Component, OnInit } from '@angular/core';
import * as data from '../services/data.services';


@Component({
  selector: 'app-home-popup',
  templateUrl: './home-popup.component.html',
  styleUrls: ['./home-popup.component.css']
})
export class HomePopupComponent implements OnInit {
	infoConvenios:any;
	opened:boolean=true;

  constructor() { }

  ngOnInit() {
  	this.infoConvenios = data.PopUp
  }

}
