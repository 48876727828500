import { Component, OnInit } from '@angular/core';
import { environment } from './../../environments/environment';

@Component({
	selector: 'app-home-contactanos',
	templateUrl: './home-contactanos.component.html',
	styleUrls: ['./home-contactanos.component.css']
})
export class HomeContactanosComponent implements OnInit {

	politica_datos:string;

	constructor() { }

	ngOnInit() {
		this.politica_datos = environment.URL_SERVER + '/formatos/politica_de_proteccion_de_datos_personales.pdf';
	}

}
