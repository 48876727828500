import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from './../../environments/environment';
import { ServiceManagerService } from '../services/service-manager.service';



@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrls: ['./home-banner.component.css']
})
export class HomeBannerComponent implements OnInit {

  @ViewChild('unlockslick') newFocus;
	banners:any;
	convenios:any = [];
  slideConfig:any;

  constructor(private service: ServiceManagerService) { }

  openLink(link){
    if(link)
      window.open(link, '_blank')
  }

  afterChange(e) {
    console.log(this.newFocus);
  }

  ngOnInit() {

    this.slideConfig = {
      autoplay: true,
      autoplaySpeed: 2000,
    }
  	this.service.getNovedades()
  		.subscribe( data => {
  			console.log(data)

        this.banners = data.filter( item => item.is_principal);

        if(this.banners.length <= 0){
           this.banners = data.map( item => {
            let description = (item.description.length <= 10) ? item.description : item.description
            return {
              picture: environment.URL_SERVER + item.image.url,
              Descripcion: description,
              link: item.link,
              title: item.title
            }
          });
         }else{
            this.banners = [this.banners.map( item => {
              let description = (item.description.length <= 10) ? item.description : item.description
              return {
                picture: environment.URL_SERVER + item.image.url,
                Descripcion: description,
                link: item.link,
                principal: item.is_principal,
                title: item.title
              }
            })[0]];

            console.log(this.banners)
         }
        

       
  			
  		});
  	
  }

}
