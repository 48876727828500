import { Component, OnInit } from '@angular/core';
import { environment } from './../../environments/environment';
import { ServiceManagerService } from '../services/service-manager.service';
import { ActivatedRoute } from '@angular/router';
import { NgAnimateScrollService } from 'ng-animate-scroll';


import * as data from '../services/data.services'


@Component({
	selector: 'app-servicios',
	templateUrl: './servicios.component.html',
	styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit {
	Lista: any;
	ahorros: any = [];
	funerarios: any = [];
	clasesCred:any = [
	{ id: 1, key: "LibDes"},
	{ id: 2, key: "LibInv"},
	{ id: 3, key: "Viv"},
	{ id: 4, key: "Estu"},
	{ id: 5, key: "Vinc"},
	{ id: 6, key: "Antic"},
	{ id: 7, key: "Promo"}
	]
	fragment:string;
	link_cirular:string;

	
	constructor(private service: ServiceManagerService, private route:ActivatedRoute, private animateScrollService: NgAnimateScrollService) { }


	ngAfterViewInit(): void {

		this.route.fragment.subscribe( data => {

			if(data){
				try {
					setTimeout( () => {
						document.querySelector('#' + data).scrollIntoView();
						//this.animateScrollService.scrollToElement(data, 200)	
					}, 500)
					
				} catch (e) { }
			}

		})

	}

	ngOnInit() {

		this.link_cirular = environment.URL_SERVER +  '/formatos/circular_renovacion_poliza_olivos_2018_2019.pdf'
		this.service.getServicios()
		.subscribe(services => {
			this.ahorros = services.ahorro.map( item => {
				item.image.url = environment.URL_SERVER + item.image.url
				item.image.thumb.url = environment.URL_SERVER + item.image.thumb.url
				return item;
			});

			this.funerarios = services.funerarios.map( item => {
				item.image.url = environment.URL_SERVER + item.image.url
				item.image.thumb.url = environment.URL_SERVER + item.image.thumb.url
				return item;
			});

			var index = 0, i = 0;
			for(let tipo of services.tipo_creditos){

				console.log(index, i)

				services.tipo_creditos[i].key = 'item_'+(index+1)
				services.tipo_creditos[i].image.url = environment.URL_SERVER + tipo.image.url

				i++;
				if(index < 6){
					index++;
					
				}else{
					index = 0;
				}
			}


			this.clasesCred = services.tipo_creditos


			let index_service = 0;
			this.Lista = services.creditos.map( (item, i) => {
				if(this.clasesCred.filter( i => i.id == item.type_credit_id)[0]){
					let cred = {};
					cred["id"] = (index_service+1);
					cred["Tipo"] = item.type_credit_id;
					cred["Description"] = item.description;
					cred["Titulo"] = item.sub_title;
					cred["Objeto"] = item.name;
					cred["TextIcon"] = item.name;
					cred["Clase"] = this.clasesCred.filter( i => i.id == item.type_credit_id)[0].key;
					cred["Icon"] = this.clasesCred.filter( i => i.id == item.type_credit_id)[0].image.url;
					cred["Picture"] = environment.URL_SERVER + item.image.url;

					index_service++;

					return cred
				}

				
			}).filter(x => x != undefined);


		})

	}

}
