import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appImagesSizesSquare]'
})
export class ImagesSizesSquareDirective {
	divW:any;
	divH:any;
	divAspect:any;
	position:number = 0;

	checksizeLoc(){
		this.el.nativeElement.children[0].onload=()=>{
			this.divW = this.el.nativeElement.clientWidth;		
			this.divH = this.divW;	 
			this.divAspect = this.divW / this.divH;
		

			var h = this.el.nativeElement.children[0].naturalHeight;
			var w = this.el.nativeElement.children[0].naturalWidth;	
			var aspectRatio = w / h;

			if(aspectRatio >= this.divAspect){
				this.el.nativeElement.children[0].style.height = this.divH + "px";
				this.el.nativeElement.children[0].style.width = (this.divH * aspectRatio) +  "px";
				var ml = ((this.divW / 2) - (this.divH * aspectRatio) / 2);
				this.el.nativeElement.children[0].style.marginLeft = ml + "px"
				this.el.nativeElement.children[0].style.marginTop = "0px"
			} else {
				this.el.nativeElement.children[0].style.width = this.divW + "px";
				console.log (this.el.nativeElement.children[0].style.width)
				this.el.nativeElement.children[0].style.height = (this.divW) +  "px";
				console.log (this.el.nativeElement.children[0].style.height)
				var mt = (this.divH / 2) - ((this.divW / 2));
				this.el.nativeElement.children[0].style.marginTop = mt + "px"
				this.el.nativeElement.children[0].style.marginLeft = "0px"
			}
		}
	}


	ngOnInit(){
		this.checksizeLoc()
	}


    constructor(private el: ElementRef) {
    	
    }
}
