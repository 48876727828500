  export const banner =  [   
  {
    "id":1,
    "Titulo": "Titulo",
    "Descripcion": "Esta es una <a class='Resaltar'>descripción</a> corta del convenio",
    "picture":"./assets/img/ImgCuadr_0003_stock-photo-tower-from-sand-and-blue-flag-on-the-coast-600653054.jpg",
  },
  {
    "id":2,
    "Titulo": "Titulo",
    "Descripcion": "Esta es una <a class='Resaltar'>descripción</a> corta del convenio",
    "picture":"./assets/img/ImgCuadr_0004_stock-photo-toward-adventure-girl-relaxing-and-enjoying-road-trip-happy-girl-rides-into-the-sunset-in-vint.jpg",
  },
  {
    "id":3,
    "Titulo": "Titulo",
    "Descripcion": "Esta es una <a class='Resaltar'>descripción</a> corta del convenio",
    "picture":"./assets/img/ImgCuadr_0006_stock-photo-plant-growing-in-savings-coins-investment-and-interest-concept-365284043.jpg",
  },
  {
    "id":4,
    "Titulo": "Titulo",
    "Descripcion": "Esta es una <a class='Resaltar'>descripción</a> corta del convenio",
    "picture":"./assets/img/ImgCuadr_0007_stock-photo-people-charity-family-real-estate-and-home-concept-close-up-of-man-and-girl-holding-house-keys.jpg",
  },
  {
    "id":5,
    "Titulo": "Titulo",
    "Descripcion": "Esta es una <a class='Resaltar'>descripción</a> corta del convenio",
    "picture":"./assets/img/ImgCuadr_0009_stock-photo-mother-and-daughter-enjoying-sunset-on-a-lake-387450340.jpg",
  },
  {
    "id":6,
    "Titulo": "Titulo",
    "Descripcion": "Esta es una <a class='Resaltar'>descripción</a> corta del convenio",
    "picture":"./assets/img/ImgCuadr_0011_stock-photo-healthy-woman-celebrating-during-a-beautiful-sunset-happy-and-free-556808413.jpg",
  },
  {
    "id":7,
    "Titulo": "Titulo",
    "Descripcion": "Esta es una <a class='Resaltar'>descripción</a> corta del convenio",
    "picture":"./assets/img/ImgCuadr_0012_stock-photo-healthy-food-vegetables-for-heart-heath-on-wooden-turquoise-background-1116095816.jpg",
  },
  {
    "id":8,
    "Titulo": "Titulo",
    "Descripcion": "Esta es una <a class='Resaltar'>descripción</a> corta del convenio",
    "picture":"./assets/img/ImgCuadr_0013_stock-photo-hand-of-business-woman-wearing-wood-watch-has-smart-phone-mouse-plant-and-keyboard-placed-on-7.jpg",
  },
]

export const ConvCategorias = [
  {
    "id":"",
    "Nombre":"Todos",
    "Clase":"Todos",
  },
  {
    "id":1,
    "Nombre":"Turismo",
    "Clase":"Turismo",
  },
  {
    "id":2,
    "Nombre":"Salud",
    "Clase":"Salud",
  },
  {
    "id":3,
    "Nombre":"Seguros",
    "Clase":"Seguros",
  },
  {
    "id":4,
    "Nombre":"Tecnología",
    "Clase":"Tecnologia",
  },
  {
    "id":5,
    "Nombre":"Concesionarios",
    "Clase":"Concesionarios",
  },  
]

export const PopUp =  [   
  {
    "id":1,
    "Cat_Id":1,
    "picture":"./assets/img/conv1.jpg",
    "Title":"Óptica Colombiana",
    "Short_Desc":"Esta es una descripción corta",
    "Descuento":"20%",
    "Long_Desc":"Esta es una descripción larga del convenio",

  },
]

export const conveniosSlider =  [   
  {
    "id":1,
    "Cat_Id":1,
    "picture":"./assets/img/conv1.jpg",
    "Title":"Óptica Colombiana",
    "Short_Desc":"Esta es una descripción corta",
    "Descuento":"20%",
    "Long_Desc":"Esta es una descripción larga del convenio",

  },
  {
    "id":2,
    "Cat_Id":2,
    "picture":"./assets/img/conv2.jpg",
    "Title":"Merpes",
    "Short_Desc":"Esta es una descripción corta",
    "Descuento":"20%",
    "Long_Desc":"Esta es una descripción larga del convenio",

  },
  {
    "id":3,
    "Cat_Id":3,
    "picture":"./assets/img/conv3.jpg",
    "Title":"Bike Hike",
    "Short_Desc":"Esta es una descripción corta",
    "Descuento":"20%",
    "Long_Desc":"Esta es una descripción larga del convenio",

  },
  {
    "id":4,
    "Cat_Id":4,
    "picture":"./assets/img/conv4.jpg",
    "Title":"Clinica Barraquer",
    "Short_Desc":"Esta es una descripción corta",
    "Descuento":"20%",
    "Long_Desc":"Esta es una descripción larga del convenio",

  },
  {
    "id":5,
    "Cat_Id":5,
    "picture":"./assets/img/conv5.jpg",
    "Title":"Naturaleza & Descanso",
    "Short_Desc":"Esta es una descripción corta",
    "Descuento":"20%",
    "Long_Desc":"Esta es una descripción larga del convenio",

  },
  {
    "id":6,
    "Cat_Id":1,
    "picture":"./assets/img/conv6.jpg",
    "Title":"La Cabaña Alpina",
    "Short_Desc":"Esta es una descripción corta",
    "Descuento":"20%",
    "Long_Desc":"Esta es una descripción larga del convenio",

  },
  {
    "id":7,
    "Cat_Id":2,
    "picture":"./assets/img/conv7.jpg",
    "Title":"Popsy",
    "Short_Desc":"Esta es una descripción corta",
    "Descuento":"20%",
    "Long_Desc":"Esta es una descripción larga del convenio",

  },
  {
    "id":8,
    "Cat_Id":5,
    "picture":"./assets/img/conv4.jpg",
    "Title":"Título convenio",
    "Short_Desc":"Esta es una descripción corta",
    "Descuento":"20%",
    "Long_Desc":"Esta es una descripción larga del convenio",

  },
 ]

 export const Opiniones =  [   
  {
    "id":1,
    "Opinion": "Lorem ipsum dolor sit amet, <a class='Resaltar'> consectetur </a> adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "Nombre": "Juan Florez",
    "Cargo":"Gerente Operaciones",
  },
  {
    "id":2,
     "Opinion": "Lorem ipsum dolor sit amet, <a class='Resaltar'>consectetur </a> adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "Nombre": "Juan Florez",
    "Cargo":"Gerente Operaciones",
  },
  {
    "id":3,
    "Opinion": "Lorem ipsum dolor sit amet, <a class='Resaltar'>consectetur </a> adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "Nombre": "Juan Florez",
    "Cargo":"Gerente Operaciones",
  },
  {
    "id":4,
     "Opinion": "Lorem ipsum dolor sit amet, <a class='Resaltar'>consectetur </a> adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "Nombre": "Juan Florez",
    "Cargo":"Gerente Operaciones",
  },
]

export const creditos =  [   
  {
    "id":1,
    "Clase":"LibDes",
    "Titulo": "Crédito Ordinario",
    "Picture":"./assets/img/SliderCred_0006_Consumo.jpg",
    "Objeto": "Libre destino, consumo.",
    "Cupo":"Hasta el 100% el valor de los ahorros permanentes, los intereses capitalizados más los aportes  personales (sujeto a cumplimiento de requisitos).",
    "Antiguedad":"Mínima seis (6) meses consecutivos como asociado al Fondo.",
    "Plazo":"Hasta 36 meses.",
    "Amortizacion":"Mensual, mensual y semestral ó fijo.",
    "IntCorriente":"Aprobado por la Junta Directiva - Ver tasa vigente.",
    "IntMora":"Aprobada por la Junta Directiva - Ver tasa vigente.",
    "Garantia":"Garantizado con los ahorros y los aportes personales del solicitante y firma de la correspondiente libranza.",
    },
  {
    "id":2,
    "Clase":"LibInv",
    "Titulo": "Crédito Extraordinario",
    "Picture":"./assets/img/SliderCred_0005_Libre.jpg",
    "Objeto": "Libre Inversión",
    "Cupo":"Se determinará tomando hasta 4 veces el valor de los ahorros permanentes más los aportes personales.(sujeto a cumplimiento de requisitos).",
    "Antiguedad":"Mínima doce (12) meses consecutivos como asociado al Fondo",
    "Plazo":"Hasta 60 meses.",
    "Amortizacion":"Mensual, mensual y semestral ó fijo",
    "IntCorriente":"Aprobado por la Junta Directiva",
    "IntMora":"Aprobado por la Junta Directiva",
    "Garantia":"Garantizado con pagaré suscrito por el deudor y la firma de uno o más codeudores solidarios, así: Cuando los créditos extraordinarios superen los depósitos totales (ahorros mas aportes personales) y supere también en veinte (20) SMLV su valor (hoy $14.754.340), se exigirá dos codeudores solidarios. En caso contrario se exigirá solo uno.",
    },
    {
    "id":3,
    "Clase":"Viv",
    "Titulo": "Crédito Extraordinario",
    "Picture":"./assets/img/SliderCred_0004_Vivienda.jpg",
    "Objeto": "Complementario de Vivienda",
    "Cupo":"Se determinará tomando hasta 6 veces el valor de los ahorros permanentes más los aportes personales (sujeto a cumplimiento de requisitos).",
    "Antiguedad":"Mínima veinticuatro (24) meses consecutivos como asociado al Fondo",
    "Plazo":"Hasta 60 meses.",
    "Amortizacion":"Mensual, mensual y semestral",
    "IntCorriente":"Aprobada por la Junta Directiva",
    "IntMora":"Aprobada por la Junta Directiva",
    "Garantia":"Garantizado con pagaré suscrito por el deudor y la firma de uno o más codeudores solidarios, así: Cuando los créditos extraordinarios superen a los depósitos totales (ahorros mas aportes personales) y supere también en veinte (20) SMLV su valor (hoy $14.754.340), se exigirá dos codeudores solidarios. En caso contrario se exigirá solo uno.",
    "Requisito":"Ser beneficiario de crédito para vivienda otorgado por el Banco o Filial",
    },
    {
    "id":4,
    "Clase":"Estu",
    "Titulo": "Crédito Extraordinario",
    "Picture":"./assets/img/SliderCred_0003_Estudio.jpg",
    "Objeto": "Estudio",
    "Cupo":"Hasta el 80% del valor de la orden de la matrícula universitaria del asociado, siempre y cuando este valor este dentro de las cuatro (4) veces el monto de los ahorros permanentes más los aportes personales (sujeto a cumplimiento de requisitos).",
    "Antiguedad":"Mínima doce (12) meses consecutivos como asociado al Fondo",
    "Plazo":"Hasta 6 meses",
    "Amortizacion":"Mensual, mensual y semestral ó fijo",
    "IntCorriente":"Aprobada por la Junta Directiva",
    "IntMora":"Aprobada por la Junta Directiva",
    "Garantia":"Garantizado con pagaré suscrito por el deudor y la firma de uno o más codeudores solidarios, así: Cuando los créditos extraordinarios superen a los depósitos totales (ahorros mas aportes personales) y supere también en veinte (20) SMLV su valor (hoy $14.754.340), se exigirá dos codeudores solidarios. En caso contrario se exigirá solo uno.",
    },
    {
    "id":5,
    "Clase":"Vinc",
    "Titulo": "Crédito Extraordinario",
    "Picture":"./assets/img/SliderCred_0002_Vinculacion.jpg",
    "Objeto": "Vinculación",
    "Cupo":"Hasta cuatro (4) SMLV (sujeto a cumplimiento de requisitos).",
    "Antiguedad":"Mínima Un (1) mes como asociado al Fondo y un año de vinculación al Banco de Bogotá o a la Filial correspondiente.",
    "Plazo":"Hasta 12 meses",
    "Amortizacion":"Mensual, mensual y semestral ó fijo",
    "IntCorriente":"Aprobada por la Junta Directiva",
    "IntMora":"Aprobada por la Junta Directiva",
    "Garantia":"Garantizado con pagaré suscrito por el deudor y la firma de un codeudor.",
    },
    {
    "id":6,
    "Clase":"Antic",
    "Titulo": "Anticipo",
    "Picture":"./assets/img/SliderCred_0001_Anticipo.jpg",
    "Objeto": "de prima semestral o antigüedad",
    "Cupo":"Hasta el 50% del sueldo mensual del asociado solicitante (sujeto a cumplimiento de requisitos), teniendo en cuenta lo estipulado en el artículo 3° del reglamento de crédito (descárguelo en la pestaña “Conócenos”).",
    "Antiguedad":"Mínima 12 meses como asociado al Fondo",
    "Plazo":"Hasta 6 meses",
    "Amortizacion":"Fijo a la siguiente prima.",
    "IntCorriente":"Aprobada por la Junta Directiva",
    "IntMora":"Aprobada por la Junta Directiva",
    "Garantia":"Ver artículo 9º. Del reglamento de Crédito (descárguelo en la pestaña “Conócenos”)",
    },
    {
    "id":7,
    "Clase":"Promo",
    "Titulo": "Promociones",
    "Picture":"./assets/img/SliderCred_0000_Promociones.jpg",
    "Objeto": "Promociones",
    "Cupo":"Hasta cuatro (4) SMLV (sujeto a cumplimiento de requisitos).",
    "Antiguedad":"Mínima doce (12) meses consecutivos como asociado al Fondo",
    "Plazo":"Hasta 12 meses",
    "Amortizacion":"Mensual, mensual y semestral o fijo",
    "IntCorriente":"Aprobada por la Junta Directiva",
    "IntMora":"Aprobada por la Junta Directiva",
    "Garantia":"arantizado con pagaré suscrito por el deudor y la firma de un codeudor solidarios.",
    "Requisito":"Exclusivamente compra de artículos ofrecidos por los proveedores que el Fondo ha elegido por condiciones de precios, calidad y garantías.",
    },
]

export const Historia = [
  {
    "id":1,
    "anno":"1945",
    "Descripcion":"El Fondo de Empleados es una entidad de la economía solidaria fundada el 24 de agosto 1945, con la denominación “Fondo Privado de los Empleados del Banco de Bogotá”, con personería jurídica expedida por el entonces Ministerio de Gobierno bajo la Resolución 033 del 31 de enero de 1946.",
  },
  {
    "id":2,
    "anno":"1990",
    "Descripcion":"El Fondo celebra sus 45 años de existencia, cambia su imagen y su denominación por (Fondo de Empleados del Banco de Bogotá) y reforma sus estatutos para ajustarlos al Decreto 1481 de 1989.",
  },
  {
    "id":3,
    "anno":"2009",
    "Descripcion":"En diciembre de 2009, el Fondo de Empleados del Banco de Bogotá renovó su imagen, para mantenerla asociada con la del Banco de Bogotá.",
  },
  {
    "id":4,
    "anno":"2018",
    "Descripcion":"En la actualidad, Fondo de Empleados del Banco de Bogotá tiene cubrimiento nacional y continúa cumpliendo con sus objetivos fundamentales: Fomentar el ahorro y otorgar préstamos a sus asociados con el fin de cubrir necesidades financieras y mejorar la calidad de vida.",
  },
]

export const junta =[
  {
    "id":1,
    "Clase":"JuntaDir",
    "Titulo":"Junta Directiva",
    "anno": "2018 - 2020",
    "Texto1":"<h1>Principales<h1><a>Julian Alfonso Sinisterra Reyes <br/>Fernando Baquero Gacharna <br/>Graciela Rey Barbosa <br/> Diego Alejandro Montoya Ossa</a>",
    "Texto2":"<h1>Suplentes<h1><a>Nohora Betty Muñoz Sossa <br/> Verónica Rocha Corredor <br/> Fernando Enrique Escobar Arango <br/> Carlos Fernando Nieto Martínez <br/> Luis Arturo Ortiz Rodríguez</a>",
  },
  {
    "id":2,
    "Clase":"ControlSoc",
    "Titulo":"Comité de Control Social",
    "anno": "2018 - 2020",
    "Texto1":"<h1>Principales<h1><a>Guillermo Cardona Sánchez <br/> Gustavo Adolfo Prada Forero <br/> Sonia del Carmen Cely Amézquita</a>",
    "Texto2":"<h1>Suplentes<h1><a>Francisco Sánchez Zambrano <br/> Diana Carolina Romero Nieto <br/> Daniel Gustavo Aguilar Hernández</a>",
  },
  {
    "id":3,
    "Clase":"Apelaciones",
    "Titulo":"Comité de apelaciones",
    "anno": "2018 - 2020",
    "Texto1":"<h1><h1/><a>Jaime Arias Hernández  - c.c. 19&#39;417,229 <br/> César Orlando León Torres - c.c. 79&#39;443,814 <br> Alvaro Laureano Guerrero Cerón -  c.c. 19&#39;422,652<a/>",
  },
  {
    "id":4,
    "Clase":"RevFisc",
    "Titulo":"RevisorFiscal",
    "anno": "REVISAR AUDITORES LTDA No 830.093.979.4",
    "Texto1":"<h1>Principal<h1><a>María Patricia Castro Sánchez <br/>C.C. 51.990.309 <br/>patriciacastro@revisarauditores.com.co <br/>T.P. 133591 T.</a>",
    "Texto2":"<h1>Suplente<h1><a>Luis Armando Contreras Paez <br/> C.C. 79.461.524 <br/> direccionrf@revisarauditores.com.co <br/> T.P. 49964 T.</a>",
  },
]

export const formulario = [
  {
    "id":1,
    "Nombre": "Formulario de afiliación o actualización de datos",
    "Link": "/formulario",
    "router": "internal"
  },
  {
    "id":2,
    "Nombre": "Autorización Central de Riesgos",
    "Link": "/formatos/autorizacion_central_riesgos.doc",
    "router": "link"
  },
  {
    "id":3,
    "Nombre": "Autorización para el Tratamiento de Datos Personales",
    "Link": "/formatos/autorizacion_tratamiento_datos_personales.docx",
    "router": "link"
  },
  {
    "id":4,
    "Nombre": "Exención GMF",
    "Link": "/formatos/exencion_gmf.doc",
    "router": "link"
  },
  {
    "id":5,
    "Nombre": "Exención GMF Varios Proveedores",
    "Link": "/formatos/exencion_gmf_proveedores.doc",
    "router": "link"
  },
  {
    "id":6,
    "Nombre": "Libranza",
    "Link": "/formatos/libranza.xlsx",
    "router": "link"
  },
  {
    "id":7,
    "Nombre": "Solicitud y Autorización de Giro a Terceros",
    "Link": "/formatos/solicitud_autorizacion_giro_terceros.doc",
    "router": "link"
  },
  {
    "id":8,
    "Nombre": "Formato Balance",
    "Link": "/formatos/Balance.xlsx",
    "router": "link"
  },
  {
    "id":9,
    "Nombre": "Formato para Crédito Ordinario",
    "Link": "/formatos/credito_ordinario.xlsx",
    "router": "link"
  },
  {
    "id":10,
    "Nombre": "Formato para Crédito Extraordinario",
    "Link": "/formatos/credito_extraordinario.xlsx",
    "router": "link"
  },

]

export const servcred =  [   
  {
    "id":1,
    "Clase":"LibDes",
    "Titulo": "Ordinario",
    "Picture":"./assets/img/SliderCred_0006_Consumo.jpg",
    "Plazo":"36 meses",
    "TasaEa":"7.27%",
    "TasaNa":"7.0411%",
    "Cupo":"Aportes + Ahorro + Intereses K",
    "Capacidad":"Hasta 1 vez",
    },
  {
    "id":2,
    "Clase":"LibInv",
    "Titulo": "Extraordinario",
    "Picture":"./assets/img/SliderCred_0005_Libre.jpg",
    "Plazo":"36 meses    48 meses    60 meses",
    "TasaEa":"11.68%    13.96%    15.12%",
    "TasaNa":"11.1018%   13.1424%   14.1653%",
    "Cupo":"Aportes + Ahorro",
    "Capacidad":"Hasta 4 veces",
    },
    {
    "id":3,
    "Clase":"Viv",
    "Titulo": "Complementario de vivienda",
    "Picture":"./assets/img/SliderCred_0004_Vivienda.jpg",
    "Plazo":"48 meses    60 meses",
    "TasaEa":"10.56%   12.82%",
    "TasaNa":"10.0840%   12.1212%",
    "Cupo":"Aportes + Ahorro",
    "Capacidad":"Hasta 6 veces",
    },
    {
    "id":4,
    "Clase":"Estu",
    "Titulo": "Estudio",
    "Picture":"./assets/img/SliderCred_0003_Estudio.jpg",
    "Plazo":"6 meses",
    "TasaEa":"10.56%",
    "TasaNa":"10.0840%",
    "Cupo":"Aportes + Ahorro",
    "Capacidad":"Hasta 4 veces",
    },
    {
    "id":5,
    "Clase":"Vinc",
    "Titulo": "Vinculación",
    "Picture":"./assets/img/SliderCred_0002_Vinculacion.jpg",
    "Plazo":"12 meses",
    "TasaEa":"11.68%",
    "TasaNa":"11.1018%",
    "Cupo":"Hasta 4 S.M.L.V",
    },
    {
    "id":6,
    "Clase":"Antic",
    "Titulo": "Anticipo de prima",
    "Picture":"./assets/img/SliderCred_0001_Anticipo.jpg",
    "Plazo":"6 meses",
    "TasaEa":"10.56%",
    "TasaNa":"10.0840%",
    "Cupo":"Hasta el 50* del sueldo mensual",
    },
    {
    "id":7,
    "Clase":"Promo",
    "Titulo": "Promoción",
    "Picture":"./assets/img/SliderCred_0000_Promociones.jpg",
    "Plazo":"12 meses 18 meses",
    "TasaEa":"11.68%",
    "TasaNa":"11.1018%",
    "Cupo":"Aportes + Ahorro",
    "Capacidad":"Hasta 4 veces",
    },
]

export const contacto = [
  {
    "id":1,
    "Clase":"Asoc",
    "Area":"Servicio al asociado",
    "Correo":"wgordil@bancodebogota.com.co",
    "Ext":"Ext - 1698",
  },
  {
    "id":2,
    "Clase":"Cont",
    "Area":"Contabilidad y cartera",
    "Correo":"jgomez9@bancodebogota.com.co",
    "Ext":"Ext - 1649",
  },
  {
    "id":3,
    "Clase":"Oper",
    "Area":"Operaciones",
    "Correo":"eherna7@bancodebogota.com.co",
    "Ext":"Ext - 1177",
  },
  {
    "id":4,
    "Clase":"Segur",
    "Area":"Seguros y servicios",
    "Correo":"dmanza1@bancodebogota.com.co",
    "Ext":"Ext - 1697",
  },
  {
    "id":5,
    "Clase":"Sist",
    "Area":"Sistemas",
    "Correo":"crami18@bancodebogota.com.co",
    "Ext":"Ext - 1384",
  },
  {
    "id":6,
    "Clase":"Febdb",
    "Area":"Fondo de Empleados",
    "Correo":"Calle 36 No 7-41 of 302",
    "Ext":"PBX: 3320032 TELS: 3382578 - 3382941 - 2456395",
  },
]
