import { Component, OnInit, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-creditos-slider',
  templateUrl: './creditos-slider.component.html',
  styleUrls: ['./creditos-slider.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CreditosSliderComponent implements OnInit {
	@ViewChild('slickModal') slickModal;
	@Input() data;
	BackPosition = 6;
	Position = 0;
	FrontPosition = this.Position + 1;
	SliderLength:any;
	slideConfig:any;

  constructor(private deviceService: DeviceDetectorService) { }

  ngOnInit() {
  	console.log(this.data);
  	this.slideConfig = this.deviceService.isMobile() ? 
    {
        "slidesToShow": 1, 
        "slidesToScroll": 1, 
        "centerMode": true, 
        "centerPadding": '0px',
        "prevArrow": ".BackBanner",
        "nextArrow": ".NextBanner"
    }
    :
    {
  			"slidesToShow": 3, 
  			"slidesToScroll": 1, 
  			"centerMode": true, 
  			"centerPadding": '2px',
  			"prevArrow": ".BackBanner",
  			"nextArrow": ".NextBanner"
  		};
  }

  afterChange(event){
  	this.Position = event.currentSlide;
  }

  ChangePosition(index){
    console.log(index)
  	//this.Position = index;
  	this.slickModal.slickGoTo(index)
  }

}
