import { Component, OnInit } from '@angular/core';
import * as data from '../services/data.services';
import { ServiceManagerService } from '../services/service-manager.service';

@Component({
	selector: 'app-contactanos',
	templateUrl: './contactanos.component.html',
	styleUrls: ['./contactanos.component.css']
})
export class ContactanosComponent implements OnInit {
	SliderLength:any;
	data:any;
	curr_data:any;
	index_serie:number = 6;

	ChangePosition(ev){
		let curr = this.data.filter( x => x.id == (ev+1))
		this.curr_data = curr[0]
	}

	constructor(private api: ServiceManagerService) { }

	ngOnInit() {

		this.api.getContactos().subscribe( data => {
			let max_items = data.contactos.length;
			let multiplier = Math.round(max_items / this.index_serie)

			let index = 0
			this.data = data.contactos.map( (item, i) => {
				
				let data = {
					"id":(i+1),
					"Clase":"icon_" + (index+1),
					"Area":item.title,
					"Correo":item.email,
					"Ext":item.telephone
				}

				index = (index < (this.index_serie - 1)) ? index+1 : 0
				return  data;
			})

			this.curr_data = this.data[0]

		})
		
	}

}
