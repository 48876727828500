import { Component, OnInit } from '@angular/core';
import * as data from '../services/data.services';

@Component({
  selector: 'app-convenios',
  templateUrl: './convenios.component.html',
  styleUrls: ['./convenios.component.css']
})
export class ConveniosComponent implements OnInit {
	
  constructor() { }

  ngOnInit() {
  	
  }

}
