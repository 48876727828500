import { Injectable } from '@angular/core';
import { Http, Headers, Response, URLSearchParams } from '@angular/http';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { map, expand } from 'rxjs/operators';


@Injectable()
export class ServiceManagerService {

  constructor(private http: Http) { }

  getServicios(){
  	return this.http.get(environment.API_ENDPOINT + "/getServicios")
  		.pipe(map((response: Response) => response.json()))
  }

  getBeneficios(){
  	return this.http.get(environment.API_ENDPOINT + "/getBeneficios")
  		.pipe(map((response: Response) => response.json()))
  }

  getComites(){
  	return this.http.get(environment.API_ENDPOINT + "/getComites")
  		.pipe(map((response: Response) => response.json()))	
  }

  getVinculate(){
    return this.http.get(environment.API_ENDPOINT + "/getVinculate")
      .pipe(map((response: Response) => response.json()))  
    
  }

  getNovedades(){
     return this.http.get(environment.API_ENDPOINT + "/getNovedades")
      .pipe(map((response: Response) => response.json()))  
  }

  getConvenios(){
     return this.http.get(environment.API_ENDPOINT + "/getConvenios")
      .pipe(map((response: Response) => response.json()))  
  }

  getInfo(){
     return this.http.get(environment.API_ENDPOINT + "/getInfo")
      .pipe(map((response: Response) => response.json()))  
  }

  getContactos(){
    return this.http.get(environment.API_ENDPOINT + "/getContactos")
      .pipe(map((response: Response) => response.json()))  
  }

  getOpiniones(){
    return this.http.get(environment.API_ENDPOINT + "/getOpiniones")
      .pipe(map((response: Response) => response.json()))  
  }

  getDocuments(){
     return this.http.get(environment.API_ENDPOINT + "/getDocumentos")
      .pipe(map((response: Response) => response.json()))   
  }

  sendVinculacion(form_data:any){
    return this.http.post(environment.API_ENDPOINT + "/form", form_data)
      .pipe(map((response: Response) => response.json())) 
  }

  baseUrl(url){
    return environment.URL_SERVER + '/' +url;
  }
}
