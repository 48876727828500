export class Familiar {
	name_familiar: any
	birthdate_familiar: any
	education_familiar: any
	type_occupation_id_familiar: any
	phone_familiar: any
	type_family_id: any

	constructor(){
		this.type_occupation_id_familiar = "";
		this.type_family_id = "";
	}
}