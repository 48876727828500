import { Component, OnInit } from '@angular/core';
import * as data from "../services/data.services";
import { ServiceManagerService } from '../services/service-manager.service'
import { DeviceDetectorService } from 'ngx-device-detector';



@Component({
	selector: 'app-home-opiniones',
	templateUrl: './home-opiniones.component.html',
	styleUrls: ['./home-opiniones.component.css']
})
export class HomeOpinionesComponent implements OnInit {

	Opiniones:any;
	slideConfig:any = {}

	constructor(private api: ServiceManagerService, private deviceService: DeviceDetectorService) { }

	ngOnInit() {
		
		this.slideConfig = this.deviceService.isMobile() ? {
			"slidesToShow": 1, "slidesToScroll": 1
		} : { "slidesToShow": 4, "slidesToScroll": 1};


		this.api.getOpiniones().subscribe( data => {
			this.Opiniones = data.opiniones.map( (item, i) => {
				return 			 {
					"id":(i+1),
					"Opinion": item.opinion,
					"Nombre": item.name,
					"Cargo": item.position,
				}
			})
		})


	}
}
